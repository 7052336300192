// General variables
$theme-font-color: #1b252a;
$theme-body-font-color: #2f4050;
$theme-body-sub-title-color: #777777;

// Theme colors variables

$primary-color: #4466f2;
$secondary-color: #1ea6ec;
$success-color: #22af47;
$info-color: #007bff;
$warning-color: #ff9f40;
$danger-color: #ff5370;
$light-color: #f6f7fb;
$semi-dark: #aaaaaa;
$light-semi-gray: #eff0f1;
$light-gray: #e8ebf2;
$dark-gray: #898989;
$dark-color: #2a3142;
$gray-60: #999999;
$transparent-color: transparent;
$auth-bg-color: #fafafa;
$light: #f6f6f6;
$light-text: #999;

$dark-primary: #448aff;
$pitch-color: #fe8a7d;
$flower-pink: #fb6d9d;
$dark-sky: #536dfe;
$color-scarpa: #4c5667;
$color-fiord: #455a64;

$black: #000;
$white: #fff;
$btn-bhome-bg-c: #ff6060;
$btn-search-bg-c: #ffd042;
// error page color end

// gradient colors
$gradient-primary: linear-gradient($secondary-color, $primary-color);

//social colors
$fb: #50598e;
$twitter: #6fa2d8;
$google-plus: #c64e40;
$github: #8d6e63;

//error input color start
$light-body-bg-color: #f1f4fb;

//fonts
$font-work-sans: work-Sans;
$font-nunito: Nunito;
$font-open-sans: Open Sans;
$font-raleway: Raleway;
$font-serif: sans-serif;
$font-arial: arial;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;

// dark layout variables
$dark-body-background: #293240;
$dark-card-background: #2f3c4e;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.85);
$sidebar-submenu-font-color: rgba(255, 255, 255, 0.7);
$dark-border-color: #374558;
$dark-editor-document: #2b2b2b;

//General tag settings
$body-bg-color: rgba(246, 246, 246, 0.6);
$body-font-size: 12px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ul-padding-right: 0px;
$ancher-color: $primary-color;
$btn-focus-box-shadow: none;
$all-focus-outline-color: $primary-color;

$paragraph-font-size: 13px;
$paragraph-line-height: 1.7;
$paragraph-letter-spacing: 0.7px;

$code-tag-color: $primary-color;
$code-tag-bg-color: $light-color;
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding: 15px;
$blockquote-border: 4px solid $light-gray;

$small-tag-padding: 10px;
$small-tag-color: $dark-color;

$pre-tag-bg-color: rgba($primary-color, 0.05);
$pre-tag-padding: 20px 0;

$list-group-active-bg-color: $primary-color;
$list-group-active-border-color: $primary-color;
$list-group-img-size: 40px;
$list-group-margin: 10px;

// Tables settings
$horizontal-border-color: #dee2e6;
$table-b-margin: 0px;
$table-heading-color: $theme-body-font-color;
$table-row-color: $theme-body-font-color;
$table-footer-font-weight: bold;
$table-inverse-color: $white;
$table-hover-color: $light;
$table-padding: 0.75rem;
$table-xl-padding: 1.25rem 2rem;
$table-xl-font: 120%;
$table-lg-padding: 0.9rem 2rem;
$table-lg-font: 110%;
$table-de-padding: 0.75rem 2rem;
$table-de-font: 100%;
$table-sm-padding: 0.5rem 2rem;
$table-sm-font: 90%;
$table-xs-padding: 0.2rem 2rem;
$table-xs-font: 80%;
$horizontal-padding: 0.75rem 2rem;
$table-inverse-bg-color: #292b2c;

//According setting
$according-card-top-margin: 10px;
$according-btn-weight: 500;
$according-btn-color: $theme-body-font-color;
$according-card-header-padding: 0.75rem 1.25rem;
$according-card-header-icon: $font-ICO;
$according-open-icon: "\eb73";
$according-close-icon: "\eb7a";

//Alert settings
$alert-hover-color: $dark-color;
$alert-border-radious: 0.15rem;
$alert-padding: 15px;
$alert-msg-icon-size: 16px;

//badge settings
$badge-padding: 0.44em 0.7em;
$badge-svg-size: 10px;

//form input settings
$form-group-margin-bottom: 1.25em;
$col-form-label-font-size: 14px;
$form-control-font-size: 14px;
$form-control-border-radious: 2px;
$form-control-border-color: $light-gray;

//breadcrumb setting
$breadcrumb-ancher-color: $white;
$breadcrumb-ancher-dark-color: $black;

//buttons setting
$btn-font-size: 12px;
$btn-padding: 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding: 0.05rem 0.4rem;

//Card settings
$card-padding: 20px;
$card-margin-bottom: 30px;
$card-border-width: 0px;
$card-border-color: $light-color;
$card-border-radious: 8px;
$card-box-shadow: 1px 5px 24px 0 rgba($primary-color, 0.05);
$card-header-font-weight: 600;
$card-header-bg-color: $white;
$card-header-font-size: 16px;
$card-header-font-transform: uppercase;
$card-header-font-color: $theme-body-font-color;
$card-header-span-size: 12px;
$card-header-span-color: $theme-body-sub-title-color;
$card-body-bg-color: $transparent-color;
$card-footer-bg-color: $white;

//footer settings
$footer_bg_color: $white;
$footer_top_bgr_color: $light-color;
$footer_link_align: right;
$footer_dark_color: $dark-color;
$footer_dark__txt_color: $white;

//form settings
$form-group-margin-bottom: 20px;
$sm-form-margin-bottom: 14px;
$form-placeholder-color: $dark-gray;
$form-placeholder-font-size: 14px;
$form-placeholder-letter-spacing: 1px;
$form-placeholder-font-weight: 100;
$form-input-border-color: $light-semi-gray;
$form-input-bg-color: $white;

//Tour settings
$tour-color: $primary-color;

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $dark-card-background;
$sidebar-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
$sidebar-overflow: auto;
$sidebar-z-index: 1000;
$sidebar-transition: 0.3s;

//sidebar profile settings
$sidebar-user-section-padding: 25px 10px;
$sidebar-user-section-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-color: $light-color;
$sidebar-profile-name-txt-transfer: uppercase;
$sidebar-profile-name-txt-weight: 600;
$sidebar-profile-name-letter-specing: 1.5px;
$sidebar-profile-name-marging: 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 20px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;

$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: rgba($white, 0.85);
$sidebar-padding-top: 8px;
$sidebar-padding-bottom: 8px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;
$sidebar-font-family: $font-nunito;

$sidebar-text-transform: capitalize;
$sidebar-heading-hover-padding: 3px;
$sidebar-hover-txt-color: $white;
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;

$sidebar-open-icon: "\f107";
$sidebar-close-icon: "\f105";
$sidebar-icon-font-family: $font-awesome;

//Header settings
$main-header-bg-color: $white;
$main-header-position: fixed;
$main-header-top: 0;
$main-header-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);

//page settings
$page-body-padding: 0 15px;
$page-body-bg-color: rgba(246, 246, 246, 0.6);
$page-body-margin-bottom: 0px;

$page-header-padding: 30px;
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-font-weight: 600;
$page-title-text-tranform: uppercase;
$breadcrumb-size: 14px;
$breadcrumb-content: "/";
$breadcrumb-svg-icon-align: text-top;

//main header left settings
$main-header-left-bg-color: $transparent-color;
$main-header-padding: 12px;
$main-header-z-index: 15;
$main-header-right-padding: 0 40px;
$main-header-right-toggle-color: $primary-color;
$main-header-right-nav-right: 0px;
$main-header-right-nav-icon-size: 18px;
$main-header-right-nav-icon-color: $primary-color;
$main-header-right-nav-icon-margin-top: 10px;
